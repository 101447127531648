<script lang="ts" setup>
import type { TransitionProps } from 'vue';
import { Transition } from 'vue';

const props = withDefaults(
  defineProps<
    Pick<TransitionProps, 'duration' | 'mode'> & {
      timingFunction?: string;
      leavingDelay?: number;
    }
  >(),
  {
    mode: 'out-in',
    duration: 200,
    timingFunction: 'ease-out',
    leavingDelay: 0,
  }
);
const slots = defineSlots<{
  default: () => VNode[];
}>();

const enterDuration = computed(() => {
  if (typeof props.duration === 'number') {
    return props.duration;
  }
  return props.duration.enter;
});

const leaveDuration = computed(() => {
  if (typeof props.duration === 'number') {
    return props.duration;
  }
  return props.duration.leave;
});

const enterTransitionStyle = `opacity ${enterDuration.value}ms ${props.timingFunction}`;
const leaveTransitionStyle = `opacity ${leaveDuration.value}ms ${props.timingFunction} ${props.leavingDelay}ms`;

const render = () => {
  return h(
    Transition,
    {
      mode: props.mode,
      name: 'fade-in',
      onBeforeEnter(el: Element) {
        if (el instanceof HTMLElement) {
          el.style.transition = enterTransitionStyle;
        }
      },
      onBeforeLeave(el: Element) {
        if (el instanceof HTMLElement) {
          el.style.transition = leaveTransitionStyle;
        }
      },
    },
    slots.default
  );
};
</script>

<template>
  <render />
</template>

<style lang="scss" scoped>
.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}
</style>
